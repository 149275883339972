import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { Box, ButtonLink, Flex, Text } from '@updater/ui-uds';
import { LinkBreak, GearSix } from '@phosphor-icons/react';
import { useRouter } from 'next/router';

type ErrorCodeContent = {
  header: string;
  description: {
    main: string;
    sub: string;
  };
  icon: React.ReactNode;
};

const errorCodeContentMap: Record<string, ErrorCodeContent | undefined> = {
  '404': {
    header: 'Error 404',
    description: {
      main: 'Hmm...',
      sub: 'We can’t seem to find the page you are looking for.',
    },
    icon: <LinkBreak width={24} weight="light" />,
  },
  '500': {
    header: 'Error 500',
    description: {
      main: 'Oops.',
      sub: 'Something broke on our end. We’re working hard to fix it!',
    },
    icon: <GearSix width={24} weight="light" />,
  },
};

const Error = ({ statusCode, error }) => {
  const router = useRouter();
  datadogRum.addError(error);

  const errorContent =
    errorCodeContentMap[
      router?.query?.statusCode === '500' ? '500' : statusCode
    ] || errorCodeContentMap['500'];

  return (
    <Box maxWidth="500px" marginTop={['xs', 'l']} padding={['s']}>
      <Flex>
        {errorContent.icon}
        <Text as="h1" variant="s" display="inline-block" marginLeft="xxs">
          {errorContent.header}
        </Text>
      </Flex>
      <Text as="p" variant="xxxl">
        {errorContent.description.main}
        <Text as="span" variant="xl" display="block" marginTop="s">
          {errorContent.description.sub}
        </Text>
        <ButtonLink href="/" marginTop="l" dataCy="back-home-button">
          Back to home
        </ButtonLink>
      </Text>
    </Box>
  );
};

Error.getInitialProps = ({ res, err }) => {
  // eslint-disable-next-line no-nested-ternary
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode, error: err };
};

Error.allowUnauthorized = true;

export default Error;
